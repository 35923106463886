$grid__bp-sm: 576;
$grid__bp-md: 768;
$grid__bp-xl: 1152;
$grid__cols: 6;

$map-grid-props: (
        '': 0,
        '-sm': $grid__bp-sm,
        '-md': $grid__bp-md,
        '-xl': $grid__bp-xl
);

@mixin create-mq($breakpoint, $min-or-max) {
  @if ($breakpoint == 0) {
    @content;
  } @else {
    @media screen and (#{$min-or-max}-width: $breakpoint *1px) {
      @content;
    }
  }
}

@mixin create-col-classes($modifier, $grid-cols, $breakpoint) {
  @include create-mq($breakpoint, 'min') {
    &__col#{$modifier}-offset-0 {
      margin-left: 0;
    }
    @for $i from 1 through $grid-cols {
      &__col#{$modifier}-#{$i} {
        flex-basis: (100 / ($grid-cols / $i) ) * 1%;
      }
      &__col#{$modifier}-offset-#{$i} {
        margin-left: (100 / ($grid-cols / $i) ) * 1%;
      }
    }
  }
}

@each $modifier, $breakpoint in $map-grid-props {
  @if ($modifier == '') {
    $modifier: '-xs';
  }
  @include create-mq($breakpoint - 1, 'max') {
    .hidden#{$modifier}-down {
      display: none !important;
    }
  }
  @include create-mq($breakpoint, 'min') {
    .hidden#{$modifier}-up {
      display: none !important;
    }
  }
}

.container {
  max-width: $grid__bp-xl * 1px;
  margin: 0 auto;

  &--fluid {
    margin: 0;
    max-width: 100%;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  @each $modifier, $breakpoint in $map-grid-props {
    @include create-col-classes($modifier, $grid__cols, $breakpoint);
  }
}


$dark: #0E0A25;
$dark-90: #26233B;
$dark-80: #3E3B50;
$dark-70: #565366;
$dark-60: #6E6C7C;
$dark-50: #878492;
$dark-40: #9F9DA8;
$dark-30: #B7B6BE;
$dark-20: #CFCED3;
$dark-10: #E7E7E9;
$dark-5: #F3F3F4;

$green: #11650D;
$green-90: #297424;
$green-80: #41843D;
$green-70: #589356;
$green-60: #70A36E;
$green-50: #88B286;
$green-40: #A0C19E;
$green-30: #B8D1B6;
$green-20: #CFE0CF;
$green-10: #E7F0E7;
$green-5: #F3F7F3;

$white: #FFF;

$unit-factor: 8;

$gradiant-black: linear-gradient(90.7deg, rgba(14, 10, 37, 0.9) 49.4%, rgba(14, 10, 37, 0.7) 99.4%);
$gradiant-green: linear-gradient(92.03deg, #11650D 48.29%, rgba(17, 101, 13, 0.8) 98.29%);

html {
  font-size: 100%;
}

.logo {
  width: 48px;
  height: 48px;
}

.logo_name {
  padding-left: 4px;
  font-weight: 600;
  font-size: 20px;
  line-height: 1;
  margin-top: 11px;
}

/*16px*/

body {
  background: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1.75;
  color: $dark;
}

h1, h2, h3, h4, h5 {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.3;
}

h1 {
  font-size: 3.052rem;
  font-weight: 500;
}

h2 {
  font-size: 2.441rem;
  font-weight: 400;
}

h3 {
  font-size: 1.953rem;
  font-weight: 400;
}

h4 {
  font-size: 1.563rem;
  font-weight: 400;
}

h5 {
  font-size: 1.25rem;
  font-weight: 500;
}

small, .text_small {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: 600;
}

p, blockquote, li {
  font-weight: 400;
}

strong {
  font-weight: 700;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.btn {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1;
  padding: 24px 32px;
  text-decoration: none;
  border-radius: 50px;
  letter-spacing: 0.04em;
  color: $white;
  display: inline-block;
  border: none;
  cursor: pointer;
}

.btn-dark {
  background: $gradiant-black;
  box-shadow: 0 4px 15px rgba(14, 10, 37, 0.3);
}

.btn-green {
  background: $gradiant-green;
  box-shadow: 0 4px 15px rgba(18, 101, 12, 0.2);
}

.btn-white {
  background-color: white;
  color: $dark;
  border: 1px solid $dark;
}

.btn-input {
  padding: 11px 14px 11px 19px;
  margin-left: -30px;
  height: 48px;
}

.small-btn {
  font-size: 1em;
  text-transform: none;
  padding: 10px 16px;

}

.flex {
  display: flex;
  border-radius: 50%;
}

.flex-align-center {
  justify-content: center;
}

.flex-align-center-vertically {
  align-items: center;
}

.flex-push-right {
  justify-content: flex-end;
  flex-direction: row;
}

.pt-1 {
  padding-top: $unit-factor * 1px;
}

.pt-2 {
  padding-top: $unit-factor * 2px;
}

.pt-3 {
  padding-top: $unit-factor * 2px;
}

.pt-4 {
  padding-top: $unit-factor * 4px;
}

.pt-10 {
  padding-top: $unit-factor * 8px;
}

.pt-6 {
  padding-top: $unit-factor * 6px;
}

.pb-1 {
  padding-bottom: $unit-factor * 1px;
}

.pb-2 {
  padding-bottom: $unit-factor * 2px;
}

.pb-3 {
  padding-bottom: $unit-factor * 3px;
}

.pb-5 {
  padding-bottom: $unit-factor * 5px;
}

.pb-6 {
  padding-bottom: $unit-factor * 6px;
}

.pb-8 {
  padding-bottom: $unit-factor * 8px;
}

.pb-10 {
  padding-bottom: $unit-factor * 10px;
}

.pl-2 {
  padding-left: $unit-factor * 2px;
}

.pr-1 {
  padding-right: $unit-factor * 1px;
}

.mt-3 {
  margin-top: $unit-factor * 3px;
}

.mt-4 {
  margin-top: $unit-factor * 4px;
}

.mt-6 {
  margin-top: $unit-factor * 6px;
}

.mt-8 {
  margin-top: $unit-factor * 8px;
}

.mt-10 {
  margin-top: $unit-factor * 10px;
}

.mt-15 {
  margin-top: $unit-factor * 15px;
}

.mt-18 {
  margin-top: $unit-factor * 18px;
}

.mr-1 {
  margin-right: $unit-factor * 1px;
}

.mr-4 {
  margin-right: $unit-factor * 4px;
}

.mb-6 {
  margin-bottom: $unit-factor * 6px;
}

.mb-10 {
  margin-bottom: $unit-factor * 10px;
}

.mb-15 {
  margin-bottom: $unit-factor * 15px;
}

.mb-20 {
  margin-bottom: $unit-factor * 20px;
}

.text-align-center {
  text-align: center;
}

.select {
  max-width: 170px;
}

.footer-navigation-fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  color: white;
  text-align: center;
  padding: $unit-factor * 2px 0;
  box-shadow: 0px -4px 15px rgba(14, 10, 37, 0.3);
}

img {
  max-width: 100%;
}

.text-input {
  height: 56px;
  width: 100%;
  background-color: $dark-10;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  input[type=text], input[type=password], input[type=number], input[type=date], .pac-target-input {
    font-family: 'Montserrat', sans-serif;
    background-color: $dark-10;
    border-width: 0;
    outline: none;
    height: 56px;
    width: 100%;
    padding-top: 4px;
    flex: 1;
  }

  input[type="date"] {
    padding-right: 16px;
  }

  input[type="date"]::-webkit-calendar-picker-indicator {
    position: relative;
    margin: auto;
    left: -20px;
  }

  .text-input-container {
    flex-direction: column;
    flex: 1;
  }

  .text-input-tips-text {
    position: absolute;
    margin-top: 2px;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 0.8rem;

    .text-input-tips-text-blue {
      color: $dark-50;
    }
  }

  button {
    margin-right: -20px;
    cursor: pointer;
  }
}

.flashbag {
  background-color: $green-30;
  border-radius: 24px;
  padding: 16px;
  margin-bottom: 16px;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 4px;
  margin-top: -8px;
}

.tooltip {
  .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: white;
    color: #0e0a25;
    text-align: center;
    padding: 6px;
    border-radius: 6px;
    border: 1px solid #CFCED3;

    /* Position the tooltip */
    position: absolute;
    z-index: 9999;
    bottom: 100%;
    left: 50%;
    margin-left: -77px;
  }
}

.tooltip .tooltiptext_right {
  top: -5px;
  right: 105%;
  bottom: inherit;
  left: inherit;
}

.tooltip .tooltiptext_bottom {
  top: 100%;
  left: 50%;
  right: inherit;
  bottom: inherit;
}

.tooltip:hover {
  z-index: 90;

  .tooltiptext {
    visibility: visible;
  }
}

.row-title-text {
  font-weight: 500;
}

.unit-container {
  line-height: 16px;
  padding: 16px;
  height: 48px;
  margin-right: -20px;
}

.row-title-text {
  color: $dark !important;
}

.row-content-text {
  color: $dark-70 !important;
}

i.icon {
  width: 13px;
  height: 13px;
  margin-right: 10px;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-flex;
  vertical-align: middle;

  &.google {
    background-image: url(../images/icons/icon_google_logo.png);
  }
}

.step-container {
  background-color: $dark-10;
  height: 8px;
  border-radius: 50px;

  .current-step {
    background-color: $dark;
    height: 8px;
    border-radius: 50px;
  }
}

.card-container {
  background: #F3F3F4;
  border-radius: 10px;
  border-top: 8px solid #11650D;
  padding: 24px;
}

.big_number {
  font-size: 1.953rem;
  font-weight: 700;
  line-height: 1;
}

.choice_button {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid black;
  cursor: pointer;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.draggable-list {
  max-width: 600px;

  ul {
    border: 1px solid $dark-20;
    border-radius: 8px;

    :last-child {
      border: none;
    }
  }

  li {
    padding: 6px 16px;
    border-bottom: 1px solid $dark-20;
    cursor: move;

    :active {
      cursor: grabbing;
    }

    a {
      cursor: move;

      :active {
        cursor: grabbing;
      }
    }
  }
}

p {
  a {
    text-decoration: underline !important;
  }
}

.flex-reverse {
  flex-direction: column-reverse;
}

.filter {
  background-color: white;
  font-size: 18px;
  line-height: 18px;
  padding: 14px 0;
  width: 234px;
  text-align: center;
  display: block;
  border: 2px solid #0E0A25;
  font-weight: bold;
  border-radius: 4px;
}

.filter_inactive {
  font-weight: normal;
  border: 2px solid #CFCED3;
}

.rainbow-box {
  width: 100%;
  height: 12px;
  border-radius: 34px;
  background: linear-gradient(
                  90deg,
                  #FE0000 0%,
                  #FCB913 33%,
                  #C0D731 66%,
                  #00A654 100%
  );
}

@media (max-width: 768px) {
  .filter {
    padding: 14px 4px;
    width: inherit;
    max-width: 100%;
  }

  .container__row__padding-sm {
    padding-left: 16px;
    padding-right: 16px;
  }

  .sm-hidden {
    display: none;
  }

  .mt-sm-0 {
    margin-top: 0;
  }

  .mt-sm-4 {
    margin-top: $unit-factor * 4px;
  }

  .flex-sm-reverse {
    flex-direction: column-reverse;
  }

  .flex-sm-align-center {
    justify-content: center;
  }

  .column {
    flex: 1;
  }

  h1 {
    font-size: 2.441rem;
  }
}

@media (min-width: 769px) and (max-width: 1152px) {
  .container__row__padding-md {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (min-width: 769px) {
  .pl-xl-1 {
    padding-left: $unit-factor * 1px;
  }

  .pl-xl-2 {
    padding-left: $unit-factor * 2px;
  }

  .pl-xl-4 {
    padding-left: $unit-factor * 4px;
  }

  .pr-xl-1 {
    padding-right: $unit-factor * 1px;
  }

  .mt-xl-15 {
    margin-top: $unit-factor * 15px;
  }

  .flex-xl-push-right {
    justify-content: flex-end;
    flex-direction: row;
  }
}